.services-container {
  position: relative;
  padding: 1rem; /* Default padding for smaller screens */
  font-family: 'Trocchi', serif;
  text-align: center;
}

/* Media query for screens wider than 520px */
@media screen and (min-width: 520px) {
  .services-container {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}


.services-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.services-content {
  position: relative;
  z-index: 1;
  color: #ffffff;
}

.services h2 {
  font-size: 40px;
  color: #ffffff;
  margin-bottom: 20px;
  font-weight: bold;
}

.services-description {
  color: #ffffff;
  margin-bottom: 3rem;
  padding-bottom: 1rem;
  border-bottom: 2px dotted #ffffff;

}

.services ul {
  list-style-type: disc;
  color: #ffffff;
  margin-left: 20px;
}

.services li {
  margin-bottom: 10px;
}

.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.service {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}

.service img {
  width: 100%;
  height: auto;
}

.service-details {
  margin-top: 10px;
  color: #ffffff;
  font-size: 1rem;
  padding-bottom: 2rem;
}

@media (min-width: 700px) {
  .service-details {
    font-size: 1.2rem;
  }
}


.service h3 {
  margin-top: 1.5rem; /* Add margin to create space between h5 and h3 */
}

.testimonial h4 {
  padding-top: 2rem;
  border-top: 1px dotted #ffffff;
}
