.get-a-quote {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .get-a-quote h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #fff;
  }
  
  .get-a-quote p,
  .get-a-quote label {
    margin-bottom: 20px;
    color: #fff;
    width: -webkit-fill-available;
  }
  
  .get-a-quote input,
  .get-a-quote textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Ensure the width includes padding */
  }
  
  .get-a-quote input[type="text"],
  .get-a-quote input[type="email"],
  .get-a-quote input[type="tel"],
  .get-a-quote textarea {
    margin-bottom: 10px;
  }
  

  
  .get-a-quote button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .get-a-quote button:hover {
    background-color: #0056b3;
  }
  
  .get-a-quote span.error {
    color: #ff0000;
  }
  