


@font-face {
  font-family: 'Trocchi';
  src: url('fonts/Trocchi/Trocchi-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@media (min-width: 992px) {
  .navbar-nav {
    margin-left: auto;
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-custom {
  background-color: #2CB2AF;
  font-family: 'Trocchi', serif;
  color: white;
  opacity: 93%;
  z-index: 1000;
}

.custom-link {
  border: 3px solid;
  border-radius: 20%;
  border-color: transparent;
  transition: border-color 0.2s ease;
}

.custom-link:hover {
  border-color: yellow;
  background-color: #2ba19f;
 
}

#root > div > nav > div > div > button:hover {
  border-color: yellow;
  background-color: #2fccc9
}

.dropdown-menu {

  background-color: #2CB2AF;
  opacity: 91%;
}

.dropdown-item {
  border: 3px solid;
  border-radius: 20%;
  border-color: transparent;
  transition: border-color 0.2s ease;
}

.dropdown-item:hover {
  border-color: yellow;
  background-color: #2ba19f;
 
}

.content-box {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  margin: auto;
  font-family: 'Trocchi', serif;
  z-index: 1;
}

.headline {
  background: #575c58;
  /* opacity: 81%; */
  font-family: 'Trocchi', serif;
  color: white;
}

.self-photo {
  width: 200px;
  height: auto;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
  object-fit: cover;
}

