.landing-container {
    background-image: url('../public/images/coolphoto.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .landing-content {
    text-align: center;
    color: white;
  }
  
  h1 {
    font-size: 4rem;
    margin-bottom: 2rem;
  }
  
  h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  
  .button {
    background-color: #3b84fb;
    color: white;
    border: none;
    padding: 1rem 2rem;
    font-size: 1.5rem;
    border-radius: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #2565c9;
  }
  
  @media (max-width: 768px) {
    h1 {
      font-size: 3rem;
    }
    h2 {
      font-size: 2rem;
    }
    button {
      font-size: 1rem;
      padding: 0.5rem 1rem;
    }
  }